<template>
	<div class="app-container" v-loading="loading">
		<el-header class="new-header">
			<div class="new-header-left">呼叫系统</div>
			<div class="new-beader-right">
        <el-button type="primary" @click="handleCallerLineReport" v-if="false">山河线路报备</el-button>
				<el-button type="primary" @click="handleInitAddIdentityInfo" v-if="false">激活电信号码</el-button>
        <el-button type="primary" @click="getSeatAccountInfo">坐席详情</el-button>
			</div>
		</el-header>
		<el-main style="background-color: #fff" v-if="showCall">
			<div class="content_top">
				<div class="phone_num">
					<div>坐席号码:{{ form.phone }}
            <i class="el-icon-edit switchPhone"  @click="editBindPhone"></i>
          </div>
					<div class="status">
            {{ form.stateName }}
          </div>
				</div>
				<div class="call-row">
					<div class="call-row-left">
						<div class="call">
							<el-input style="width: 360px; height: 44px" v-model="phone" @keyup.enter.native="handleCall" @input="modifyPhone"></el-input>
							<img src="../../assets/images/call.png" alt="" @click="handleCall" />
						</div>
					</div>
				</div>

				<div class="msg_info">
					<p>应工信部持续性整治“骚扰电话”要求，呼叫规范如下:</p>
					<p>
						1.外呼过程中请务必保证态度温和，禁止出现：言语傲慢、侮辱、恐吓、辱骂、欺诈等言语行为；
					</p>
					<p>
						2.被叫方明显表达了“拒绝”，请礼貌表示“打扰了”并及时挂断电话，做好无效标识，禁止再次拨打。
					</p>
					<p>
						如因以上原因导致被叫投诉，所有账户立即关停不退费，并保留追究相关损失赔偿的权利，望大家协助、配合，谢谢！
					</p>
				</div>
			</div>
			<el-dialog center :close-on-click-modal="false" :visible.sync="dialogForIdentity" width="600px">
				<div slot="title" class="identity-title">
					电信激活
				</div>
				<div class="identity-text">
					由于运营商管控要求，需要对外显号码进行激活，号码信息仅供运营商认证使用，不用做其它用途
				</div>
				<el-row type="flex" justify="center" class="identity-form">
					<el-col :span="16">
						<el-form ref="identityForm" @submit.native.prevent
								 :model="identityForm"
								 :rules="identityRules"
								 label-width="140px" label-position="right">
							<el-form-item label="报备手机号码" prop="phone">
								<el-input v-model="identityForm.phone" size="small"></el-input>
							</el-form-item>
							<el-form-item label="验证码" prop="vcode">
								<el-input v-model="identityForm.vcode" size="small" style="width: 70%"></el-input>
								<el-button v-if="show2" type="text" size="small" @click="handleSendVirifyCode">发送验证码</el-button>
								<span v-show="!show2" class="count">{{count2}} s</span>
							</el-form-item>
						</el-form>
					</el-col>
				</el-row>
				<div slot="footer">
					<el-button @click="handleCancelAddIdentityInfo" style="width: 123px">取消</el-button>
					<el-button @click="handleAddIdentityInfo" type="primary" style="width: 123px">确定</el-button>
				</div>
			</el-dialog>

      <el-dialog title="编辑主叫号码" :visible.sync="dialogForModifyPhone"  center width="40%" :modal-append-to-body="false">
        <el-form
            ref="modifyPhoneForm"
            :model="modifyPhoneForm"
            label-width="100px"
            :rules="modifyPhoneFormRules"
        >
          <el-row type="flex" justify="center">
            <el-col :span="12">
              <el-form-item label="主叫号码:" prop="phone">
                <el-input
                    type="text"
                    v-model="modifyPhoneForm.phone"
                    placeholder="请输入主叫号码"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer">
          <el-button type="info" @click="dialogForModifyPhone = false"
          >取消
          </el-button
          >
          <el-button type="primary" @click="handleModifyPhone">确认</el-button>
        </div>
      </el-dialog>

      <el-dialog title="坐席详情" :visible.sync="dialogForDetails">
        <el-descriptions class="margin-top" title="" border :column="2">
          <el-descriptions-item label="坐席名称">{{form.seatAccountName}}</el-descriptions-item>
          <el-descriptions-item label="状态">{{form.stateName}}</el-descriptions-item>
          <el-descriptions-item label="坐席主叫号码">{{form.phone}}</el-descriptions-item>
          <el-descriptions-item label="总分钟数">{{form.totalMinutes}}</el-descriptions-item>
          <el-descriptions-item label="已使用分钟数">{{form.usedMinutes}}</el-descriptions-item>
          <el-descriptions-item label="剩余分钟数">{{form.remainMinutes}}</el-descriptions-item>
          <el-descriptions-item label="今日呼出次数">{{form.numberOfCall}}</el-descriptions-item>
          <el-descriptions-item label="今日接通次数">{{form.numberOfConnection}}</el-descriptions-item>
          <el-descriptions-item label="今日有效呼出次数">{{form.effectiveNumberOfCall}}</el-descriptions-item>
          <el-descriptions-item label="查看报表数据">
            <span style="color: blue" @click="showSeatAccountDetails">{{"查看报表数据"}}</span>
          </el-descriptions-item>
        </el-descriptions>
      </el-dialog>
		</el-main>
		<div class="none_show" v-else>
			<div><img src="../../assets/images/wtk.png" /></div>
			<p>当前账户暂未开通公费电话,请开通后再次使用！</p>
		</div>
	</div>
</template>
<script>
import {callerLineReport, clickCall, code, getSeatInfoByUser, modifyUserPassword, white, editSeatBindPhoneNumber} from '../../api';

	export default {
		name: 'index',
		data() {
			return {
				loading: false,
				showCall: true,
				customerPhone: this.$route.query.customerPhone,
				phone: '',
				form: {
					state: '',
					stateName: '',
					applyId: '',
					applyName: '',
					userId: '',
					userName: '',
					seatAccountId: '',
					merchantName: '',
				},
				dialogForIdentity: false,
        dialogForModifyPhone: false,
        dialogForDetails: false,
				show2: true,
				count2: '',
				identityForm: {
					phone: '',
					vcode: ''
				},
				identityRules: {
          phone: [{required: true, message: '请输入报备的手机号码'}],
					vcode: [{required: true, message: '请输入验证码'}]
				},
        modifyPhoneForm: {
          phone: ""
        },
        modifyPhoneFormRules: {
          phone: [
            {required: true, message: "请输入主叫号码", trigger: "blur"},
          ],
        },
			};
		},
		created() {
			if (this.customerPhone) {
				this.phone = this.customerPhone;
			}
			this.loadDetails();
		},
		methods: {
      showSeatAccountDetails() {
        this.$router.push(`/seat-report`);
      },
      getSeatAccountInfo() {
        this.loadDetails();
        this.dialogForDetails = true
      },
      handleModifyPhone() {
        let phone = this.modifyPhoneForm.phone.trim()
        if (phone.length !== 11) {
          this.$notify({
            type: 'error',
            title: '错误',
            message: '手机号码格式错误',
          })
          return;
        }
        let params = {
          seatAccountId: this.form.seatAccountId,
          bindPhoneNumber: this.modifyPhoneForm.phone
        }
        editSeatBindPhoneNumber(params).then((data) => {
          if (data) {
            this.form.phone = this.modifyPhoneForm.phone;
          }
          this.dialogForModifyPhone = false;
        })
      },
      editBindPhone() {
        this.dialogForModifyPhone = true
        this.modifyPhoneForm.phone = this.form.phone
      },
      modifyPhone() {
        let newPhone = this.phone.replace(/\s+/g,"").replace(/[^0-9]/ig,"");
        this.phone = newPhone
      },
      handleCallerLineReport() {
        callerLineReport({phone: [this.form.phone]}).then(() => {
          this.$notify({type: 'success', title: '成功', message: '发生验证码成功！'})
        })
      },
			handleInitAddIdentityInfo() {
				this.identityForm.vcode = ''
				this.identityForm.phone = this.form.phone,
				this.dialogForIdentity = true
			},
			handleCancelAddIdentityInfo() {
				this.$refs.identityForm.clearValidate()
				this.dialogForIdentity = false
			},
			handleSendVirifyCode() {
				if (this.identityForm.phone) {
					let param = {
						...this.identityForm
					}
					white(param).then(data => {
						if (data) {
							this.$notify({title: '成功', message: '验证码发送成功，请注意查收', type: 'success'})
						}
						const TIME_COUNT = 300;
						if (!this.timer2) {
							this.count2 = TIME_COUNT;
							this.show2 = false;
							this.timer2 = setInterval(() => {
								if (this.count2 > 0 && this.count2 <= TIME_COUNT) {
									this.count2--;
								} else {
									this.show2 = true;
									clearInterval(this.timer2);
									this.timer2 = null;
								}
							}, 1000)
						}
					})
				} else {
					this.$refs.identityForm.validateField('phone')
				}
			},
			handleAddIdentityInfo() {
				this.$refs.identityForm.validate(valid => {
					if (valid) {
						let param = {
							...this.identityForm
						}
						code(param).then(() => {
							this.$refs.identityForm.clearValidate()
							this.handleCancelAddIdentityInfo()
						})
					}
				})
			},
			handleUpdatePhoneNumber() {
				this.$router.push(`/updateBindNumber/${this.csaspiId}/${this.bindPhoneNumber}`);
			},
			handleCall() {
				if (this.phone && /^[0-9]*$/.test(this.phone)) {
					let param = {
						bindPhoneNumber: this.bindPhoneNumber,
						callee: this.phone,
					};
					clickCall(param).then((data) => {
						if (data.tacticsType === 1) {
							this.$message.success('拨号成功，请注意接听电话！');
						} else {
							this.$notify({
								title: '失败',
								type: 'error',
								message: '当前线路不支持在网页外呼，请使用APP拨打',
							});
							return false;
						}
					});
				} else {
					this.$message.error('号码不正确，请查证后再拨');
				}
			},
			async loadDetails() {
				this.loading = true;
				let data = await getSeatInfoByUser();
				this.loading = false;
				if (data != null) {
					Object.assign(this.form, data);
				} else {
					this.showCall = false;
				}
			},
			handleLinkToSeatAccount() {
				this.$router.push({ name: 'phoneDetails', params: { id: this.csaiId } });
			},
		},
	};
</script>
<style>
	.select-class-copy .el-radio {
		border-radius: 2px;
	}

	.select-class-copy .el-radio .el-radio__input {
		display: none;
	}

	.select-class-copy .is-checked {
		background: #3b8cff;
	}

	.select-class-copy .is-checked .el-radio__label {
		color: #fff;
	}
</style>
<style scoped lang="scss">
	.content_top {
		padding-left: 86px;

		.phone_num {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			height: 44px;
			line-height: 44px;
			width: 300px;
		}

		.call {
			img {
				width: 24px;
				vertical-align: middle;
				margin-left: 20px;
				cursor: pointer;
			}
		}

		.msg_info {
			color: #999999;
			font-size: 18px;
			width: 500px;
		}
	}

	.none_show {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-height: 700px;

		p {
			font-size: 20px;
			font-weight: bold;
			color: rgba(153, 153, 153, 1);
		}
	}

  .switchPhone {
    color: #3b8cff;
    padding-left: 10px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    cursor: pointer;
  }

	.status {
    color: #3b8cff;
		font-family: PingFang SC;
		font-style: normal;
		font-weight: normal;
		// font-size: 14px;
		// line-height: 44px;
		cursor: pointer;
		padding: 0 10px;
	}

	.status:hover {
		color: #ff2a2a;
	}

	.bf-row-block {
		margin: 10px;
		background: #fff;
	}

	.bf-row-block .bf-row-header {
		width: 100%;
		/* border-bottom: 1px solid; */
		padding: 10px 20px;
	}

	.bf-row-header-title {
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 30px;
	}

	.bf-row-body {
		padding: 5px;
	}

	.call-row {
		line-height: 60px;
		height: 60px;
		border: 0;
		margin: 0;
		box-sizing: border-box;
		display: flex;

		.call-row-left {
			display: inline-block;
			flex: 1;
			flex-grow: 1;

			.call-row-title {
				font-size: 24px;
			}
		}

		.call-row-right {
			display: flex;
			justify-content: center; /* 水平居中 */
			align-items: center; /* 垂直居中 */
			height: 100%;
			overflow: hidden;
			box-sizing: border-box;
		}
	}
</style>
